import React from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';

import { SingleIcon } from '@ui/components/icon';

import * as S from './Description.styled';

const Description: React.FC<{ isOnline: boolean }> = ({ isOnline }) => {
  const { t } = useTranslation();
  const allowIcon = 'allow-filled';
  const denyIcon = 'delete';

  const items = [
    {
      icon: allowIcon,
      text: t('wallet_switcher.cashout'),
      fill: ['#00BC19', '#fff'],
    },
    {
      icon: isOnline ? allowIcon : denyIcon,
      text: t('wallet_switcher.betting'),
      fill: isOnline ? ['#00BC19', '#fff'] : '#E23737',
    },
    {
      icon: isOnline ? allowIcon : denyIcon,
      text: t('wallet_switcher.deposit_withdraw'),
      fill: isOnline ? ['#00BC19', '#fff'] : '#E23737',
    },
  ];

  return (
    <S.Root>
      {map(items, item => (
        <S.Item>
          <S.Icon>
            <SingleIcon
              name={item.icon}
              width="16px"
              height="16px"
              fill={item.fill}
            />
          </S.Icon>

          <S.Text>{item.text}</S.Text>
        </S.Item>
      ))}
    </S.Root>
  );
};

export default Description;
