import styled from 'styled-components';

export const Root = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0 156 188 / 10%);
  padding: ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_15};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: ${({ theme }) => theme.space_5};
`;

export const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSM};
  color: ${({ theme }) => theme.elementNuanceColor};
`;
