import React, { useMemo } from 'react';
import map from 'lodash/map';
import split from 'lodash/split';
import keys from 'lodash/keys';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import some from 'lodash/some';
import { useTranslation } from 'react-i18next';

import { TransactionItem } from '@features/transactions/components/transactionItem';
import { DATE_TEMPLATE } from '@features/core/form/components/dateInput/DateTemplates';

import formatDate from '@common/helpers/timeHelper/localizedFormat';
import { isDesktopView } from '@common/helpers/deviceUtil';

import { ITransactionSortedList } from '../Transaction.types';
import * as S from '../Transaction.styled';

export const TransactionSortedList: React.FC<ITransactionSortedList> = ({
  data,
  isAccountSummary,
  hasPendingTransactions,
}) => {
  const { t } = useTranslation();
  const grouped = useMemo(
    () => groupBy(data, item => split(item.localeZoneTimestamp, ' ')[0]),
    [data],
  );
  const dates = useMemo(() => orderBy(keys(grouped), date => date, 'desc'), [
    grouped,
  ]);

  const isPendingList = some(data, [
    'description',
    t('cashout.pending_cashout'),
  ]);

  return (
    <S.SortedList>
      {!isAccountSummary && hasPendingTransactions ? (
        <S.PendingTransactionTitle isPendingTitle={isPendingList}>
          {t(
            `transactions.${isPendingList ? 'pending' : 'closed'}_transactions`,
          )}
        </S.PendingTransactionTitle>
      ) : null}
      {map(dates, date => {
        const currentItems = grouped[date];
        const sectionTitle = formatDate(new Date(date), DATE_TEMPLATE);

        return (
          <S.SortedList key={date}>
            <S.SortedListTitle
              text={sectionTitle}
              isAccountSummary={isAccountSummary}
              isLight={!isDesktopView()}
            />
            {map(currentItems, item => (
              <TransactionItem
                key={item.transaction_id}
                transaction={item}
                isAccountSummary={isAccountSummary}
              />
            ))}
          </S.SortedList>
        );
      })}
    </S.SortedList>
  );
};

export { TransactionSortedList as Component };

export default TransactionSortedList;
