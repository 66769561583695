import styled from 'styled-components';

export enum ButtonSize {
  Big,
  Small,
}

export enum ButtonType {
  Yellow,
  Blue,
  LightOutlined,
  DarkOutlined,
  LightBlue,
}

const getDisabledStylesByType = (theme, buttonType) => {
  if (
    buttonType === ButtonType.DarkOutlined ||
    buttonType === ButtonType.LightOutlined
  ) {
    return 'opacity: 0.5; pointer-events: none;';
  }

  const isBlueBtns =
    buttonType === ButtonType.Blue || buttonType === ButtonType.LightBlue;
  const blueBtnBackground =
    buttonType === ButtonType.Blue
      ? theme.buttonTertiaryDisabledBackground
      : theme.buttonSecondaryDisabledBackgroundColor;

  return `
  color: ${
    isBlueBtns
      ? theme.buttonTertiaryDisabledTextColor
      : theme.buttonMainDisabledTextColor
  };
  background-color: ${
    isBlueBtns ? blueBtnBackground : theme.buttonMainDisabledBackgroundColor
  };
`;
};

const disabledButtonStyles = (theme, buttonType) => `
   cursor: default;

  ${getDisabledStylesByType(theme, buttonType)};
  
  &:hover {
  ${getDisabledStylesByType(theme, buttonType)};
  }
        
`;

// ButtonType.Yellow by default
const getButtonStyles = (theme, type) => {
  const boldStyles = `font-weight: ${theme.fontBold};`;
  switch (type) {
    case ButtonType.LightOutlined:
      return {
        primaryColor: theme.textMain,
        secondaryColor: 'transparent',
        hoverColor: theme.textActive,
        additionalStyles: `border: 1px solid ${theme.textMain};`,
      };
    case ButtonType.DarkOutlined:
      return {
        primaryColor: theme.textActive,
        secondaryColor: theme.textMain,
        hoverColor: theme.textMain,
        hoverBackground: theme.textActive,
        additionalStyles: `border: 1px solid ${theme.textActive}`,
      };
    case ButtonType.Blue:
      return {
        primaryColor: theme.buttonSecondaryTextColor,
        secondaryColor: theme.buttonSecondaryBackgroundColor,
        hoverColor: theme.textMain,
        hoverBackground: theme.buttonSecondaryHoverBackground,
        additionalStyles: boldStyles,
      };
    case ButtonType.LightBlue:
      return {
        primaryColor: theme.textMain,
        secondaryColor: theme.primaryBackground,
        hoverColor: theme.textMain,
        hoverBackground: theme.buttonTertiaryHoverBackground,
        additionalStyles: boldStyles,
      };
    default:
      return {
        primaryColor: theme.textActive,
        secondaryColor: theme.buttonMainBackgroundColor,
        hoverColor: theme.inputDefaultText,
        hoverBackground: theme.buttonMainHoverBackground,
        additionalStyles: boldStyles,
      };
  }
};

const getStylesByButtonType = (type, theme) => {
  const buttonStyles = getButtonStyles(theme, type);

  return `
        color: ${buttonStyles.primaryColor};
        background-color: ${buttonStyles.secondaryColor};
        ${buttonStyles?.additionalStyles};
        
        &:hover {
        background-color: ${buttonStyles?.hoverBackground ||
          buttonStyles.primaryColor};
        color: ${buttonStyles.hoverColor};
        }
`;
};

const Button = styled.button<{
  size?: ButtonSize;
  disabled?: boolean;
  buttonType?: ButtonType;
  isSingleIcon?: boolean;
}>`
  height: ${({ size }) => {
    return size === ButtonSize.Big ? '42px;' : '36px;';
  }};
  position: relative;
  padding: 0 ${({ theme }) => theme.space_5};
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  display: inline-block;
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontLG};
  width: 100%;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  border-radius: 3px;
  outline: 0;
  box-sizing: border-box;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;

  ${({ buttonType, theme }) => getStylesByButtonType(buttonType, theme)};
  ${({ disabled, theme, buttonType }) =>
    disabled && disabledButtonStyles(theme, buttonType)}
`;

export default Button;
