export interface IPossibleSizes {
  value: number;
  selected: boolean;
  banksCount: number;
  size: number;
  total: number;
  legsCount: number;
}

export interface ICompositesT {
  event_id: string;
  odds: number;
  selections: [];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IExpandLeg = any[];

export type IBettingSlipModelRange = Record<string, string | number>;

export enum IBettingslipErrorTags {
  NO_USER = 'NO_USER',
  NO_SELECTIONS = 'NO_SELECTIONS',
  MIN_SIZE = 'MIN_SIZE',
  SELECTIONS_SUSPENDED = 'SELECTIONS_SUSPENDED',
  MAX_EVENTS = 'MAX_EVENTS',
  TOO_MANY_BANKS = 'TOO_MANY_BANKS',
  LOCKED_COMBINATIONS = 'LOCKED_COMBINATIONS',
  INVALID_SYSTEM = 'INVALID_SYSTEM',
  INVALID_TYPE = 'INVALID_TYPE',
  MIN_STAKE = 'MIN_STAKE',
  NOT_VERIFIED_YEARLY = 'NOT_VERIFIED_YEARLY',
  NOT_VERIFIED_72_HRS = 'NOT_VERIFIED_72_HRS',
  NOT_VERIFIED_EMAIL = 'NOT_VERIFIED_EMAIL',
  NOT_PAYABLE_BY_BONUS_MONEY = 'NOT_PAYABLE_BY_BONUS_MONEY',
  ONLY_ONE_BOOST_IN_BETSLIP = 'ONLY_ONE_BOOST_IN_BETSLIP',
  NOT_ENOUGH_BONUS_MONEY = 'NOT_ENOUGH_BONUS_MONEY',

  // not used in the project
  MIN_TOTAL_STAKE = 'MIN_TOTAL_STAKE',
  INVALID_STAKE = 'INVALID_STAKE',
  MAX_SELECTIONS = 'MAX_SELECTIONS',
  MAX_PAYOUT = 'MAX_PAYOUT',
  MISSING_SELECTIONS = 'MISSING_SELECTIONS',
  SWITCH_ACCOUNT_TIMEOUT = 'SWITCH_ACCOUNT_TIMEOUT',
  SWITCH_ACCOUNT_IN_PROGRESS = 'SWITCH_ACCOUNT_IN_PROGRESS',
  NO_HANDLED = 'NO_HANDLED',
}
