import React from 'react';
import { useTranslation } from 'react-i18next';
import isString from 'lodash/isString';
import { useTheme } from 'styled-components';

import { Icon } from '@ui/components/icon';

import * as S from './GenericNotification.styled';
import {
  IIconType,
  INotificationIcon,
  INotificationProps,
} from './GenericNotification.types';

const GenericNotification: React.FC<INotificationProps> = ({
  iconType,
  styleType,
  title,
  onClose,
  children,
  closeHandle,
  withoutIcon,
  severalChildren,
  additionalText,
}) => {
  const { t } = useTranslation();
  const { statusSuccess, statusError } = useTheme();

  const getIcon = (): IIconType => {
    switch (iconType) {
      case INotificationIcon.success:
        return 'check-circle';
      case INotificationIcon.warning:
        return 'exclamation-circle';
      case INotificationIcon.error:
        return 'exclamation-circle';
      case INotificationIcon.base:
        return 'info-circle';
      default:
        return 'info-circle';
    }
  };

  const getIconColorByType = (): string => {
    switch (iconType) {
      case INotificationIcon.success:
        return statusSuccess;
      case INotificationIcon.error:
        return statusError;
      default:
        return 'currentColor';
    }
  };

  const isChildrenString = isString(children);

  const renderNotification = (): JSX.Element => {
    return !isChildrenString ? (
      <S.NotificationText type={iconType} severalChildren={severalChildren}>
        {children}
      </S.NotificationText>
    ) : (
      <S.NotificationText
        className="notification-text-string"
        title={title}
        type={iconType}
        severalChildren={severalChildren}
        dangerouslySetInnerHTML={{
          __html: children as string,
        }}
      />
    );
  };

  return (
    <S.Message styleType={styleType}>
      <S.Content iconType={iconType}>
        <S.Row isIcon={!!iconType && !withoutIcon} styleType={styleType}>
          <S.Row isIcon={!!iconType && !withoutIcon} styleType={styleType}>
            {iconType && !withoutIcon && (
              <Icon
                width="25px"
                height="25px"
                name={getIcon()}
                fill={getIconColorByType()}
              />
            )}
            {title ? (
              <S.ContentText styleType={styleType} type={iconType}>
                <S.Title>{title}</S.Title>
                {renderNotification()}
              </S.ContentText>
            ) : (
              renderNotification()
            )}
          </S.Row>
          {onClose && (
            <S.ButtonClose
              aria-label={t('common.buttons.close')}
              onClick={onClose}
            >
              <Icon className="close" name="close" width="14" />
            </S.ButtonClose>
          )}
        </S.Row>
      </S.Content>
      {additionalText && (
        <S.NotificationText
          className="notification-text-string"
          title={title}
          type={iconType}
          severalChildren={severalChildren}
          styleType={styleType}
          dangerouslySetInnerHTML={{
            __html: additionalText as string,
          }}
        />
      )}
      {!!closeHandle && <S.CloseHandleWrap>{closeHandle}</S.CloseHandleWrap>}
    </S.Message>
  );
};

export default GenericNotification;
