import styled from 'styled-components';

import { TextSM } from '@features/core/styles/text.styled';

export const InputContainer = styled.div<{
  isArrow: boolean;
  noMargins?: boolean;
}>`
  position: relative;

  &.with-errors {
    margin-bottom: ${({ isArrow, theme }) =>
      isArrow ? theme.space_30 : theme.space_50};

    ${({ noMargins }) => noMargins && 'margin-bottom: 0;'}

    input,
    select,
    .sufix {
      color: ${props => props.theme.statusError};
      border-color: ${props => props.theme.statusError};
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      &::placeholder {
        color: ${props => props.theme.statusError};
      }
    }

    input {
      color: ${({ theme }) => theme.inputDefaultText};
      border-radius: 2px;
    }
  }
`;

const floatErrorStyles = (theme, isFloatErrorArrowLeft) => `
  padding: ${theme.space_5} ${theme.space_10};
  border-radius: 1px;
  text-align: left;
  top: calc(100% + 10px);
  width: auto;
  right: 0;
  ${isFloatErrorArrowLeft && 'right: unset;'}

  &:after {
    content: '';
    border: 7px solid transparent; 
    border-bottom: 7px solid ${theme.statusError};
    position: absolute;
    ${isFloatErrorArrowLeft ? 'left: 13px;' : 'right: 13px;'}
    top: -13px;
    z-index: 2;
  }
  
  &:before {
    content: '';
    border: 7px solid transparent; 
    border-bottom: 7px solid ${theme.main};
    position: absolute;
    ${isFloatErrorArrowLeft ? 'left: 12px;' : 'right: 12px;'}
    top: -14px;
    z-index: 1;
  }
`;

const relativeErrorStyles = ({ theme }) => `
    position: relative; 
    margin-top: ${theme.space_10}; 
    min-height: 30px;
`;

export const Errors = styled(TextSM)<{
  floatError: boolean;
  isFloatErrorArrowLeft?: boolean;
  isRelativeErrorPosition?: boolean;
}>`
  color: ${props => props.theme.highlightWrapperBackground};
  position: absolute;
  z-index: 5;
  padding: 0 ${({ theme }) => theme.space_10};
  box-sizing: border-box;
  width: 100%;
  background: ${props => props.theme.statusError};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  a {
    text-decoration: underline;
  }

  &.with-errors {
    z-index: -1;
  }

  ${({ isRelativeErrorPosition }) =>
    isRelativeErrorPosition && relativeErrorStyles};

  ${({ floatError, theme, isFloatErrorArrowLeft }) =>
    floatError && floatErrorStyles(theme, isFloatErrorArrowLeft)};
`;
