import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonType } from '@common/interfaces';
import { isDesktopView } from '@common/helpers/deviceUtil';
import {
  resetSelfExclusion,
  useLimitsState,
} from '@common/providers/limits/useLimits';
import { setSelfExclusion } from '@common/providers/limits/helper';
import { useLongPress } from '@common/hooks/useKeyPress';

import { GenericNotification } from '@ui/components/genericNotification';
import { INotificationStyle } from '@ui/components/genericNotification/GenericNotification.types';

import * as S from './SelfLock.styled';

const isDesktop = isDesktopView();

const SelfLock: React.FC = () => {
  const { t } = useTranslation();
  const success = useLimitsState(s => s.selfExclusionForm.success);
  const error = useLimitsState(s => s.selfExclusionForm.error);
  const loading = useLimitsState(s => s.selfExclusionForm.loading);
  const iconType = success ? 'success' : 'error';
  const title = t(`bettingslip.24h_lock_${success ? 'success' : 'error'}`);
  const hasResult = !!error || success || undefined;
  const defaultText = t(`bettingslip.24h_lock_text${!isDesktop ? '_mob' : ''}`);
  const backspaceLongPress = useLongPress(
    () =>
      setSelfExclusion({
        period: '24H',
        reason: '01',
      }),
    3000,
    () => resetSelfExclusion(),
    isDesktopView(),
  );

  return (
    <S.SelfLockContainer isDefaultMessage={!hasResult}>
      <GenericNotification
        styleType={INotificationStyle.selfExclusion}
        title={hasResult && title}
        iconType={hasResult && iconType}
        additionalText={
          !success && t(error ? 'bettingslip.24h_lock_error_text' : defaultText)
        }
      />
      {!success && (
        <S.ExclusionButton
          buttonType={ButtonType.Blue}
          isError={!!error}
          {...backspaceLongPress}
          data-qa="btn-24h-lock"
        >
          {!loading &&
            t(`bettingslip.24h_lock_${error ? 'error_button_text' : 'mob'}`)}
          {loading && !success && !error && (
            <S.LoaderIcon name="sync" width="18px" height="18px" />
          )}
        </S.ExclusionButton>
      )}
    </S.SelfLockContainer>
  );
};

export { SelfLock as Component };

export default SelfLock;
