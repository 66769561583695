import {
  ISocket,
  ICMSContent,
  ICMSBlock,
  INotification,
  ISidebar,
} from '@common/providers/application/types';
import { IDisabledMarkets } from '@common/interfaces';
import { WalletsActions } from '@common/providers/payments/wallets/state';

export enum SortingKeys {
  TIME = 'time',
  CATEGORY = 'category',
}

export type ApplicationProviderState = {
  withFocus: boolean;
  socket?: ISocket;
  beat: number;
  staticPageContent: {
    [key: string]: ICMSContent;
  };
  seoContent: {
    [key: string]: ICMSContent;
  };
  cmsContent: ICMSBlock[];
  openedSidebars: ISidebar[];
  showComponent: string;
  sortingKey: SortingKeys;
  showFooter: boolean;
  isContentScrollDisabled: boolean;
  notifications: INotification[];
  openedRetryNotification: WalletsActions.GET_WALLETS | null;
  hasCategoryHeader: boolean;
  hasTopAppBanner: boolean;
  hasFooterNotification: boolean;
  openAppVersion: number;
  marketsToExclude: IDisabledMarkets;
  topLayoutHeight: number;
};

export const defaultState: ApplicationProviderState = {
  withFocus: false,
  socket: undefined,
  beat: new Date().getTime(),
  staticPageContent: {},
  seoContent: {},
  cmsContent: [],
  openedSidebars: [],
  showComponent: '',
  showFooter: true,
  isContentScrollDisabled: false,
  notifications: [],
  openedRetryNotification: null,
  sortingKey: SortingKeys.CATEGORY,
  hasCategoryHeader: true,
  hasTopAppBanner: false,
  hasFooterNotification: false,
  openAppVersion: Date.now(),
  marketsToExclude: {
    live: {},
    sport: {},
  } as IDisabledMarkets,
  topLayoutHeight: 0,
};
