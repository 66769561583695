import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';

import App from '@features/app/App';
import ContextTheme from '@features/core/theming/contexTheme';

import MainProvider from '@packages/provider/MainProvider';

import reportWebVitals from './reportWebVitals';
import './index.css';

if (
  process.env.NODE_ENV &&
  process.env.NODE_ENV === 'development' &&
  'serviceWorker' in navigator
) {
  /* eslint-disable */
  //  navigator.serviceWorker.getRegistrations().then(registrations => {
  //    for (const registration of registrations) {
  //      registration.unregister();
  //    }
  //  });
  /* eslint-enable */
  navigator.serviceWorker.register('/serviceWorker.js', {
    scope: '.',
    type: 'module',
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ContextTheme>
      <MainProvider>
        <App />
      </MainProvider>
    </ContextTheme>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
