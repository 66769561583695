import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { animated as a } from '@react-spring/web';
import { useLocation, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { ILoctationState } from '@pages/cashout/Cashout.types';

import { BetStats } from '@features/cashout/components/betStats';
import { Rejected } from '@features/cashout/components/states/rejected';
import { Accepted } from '@features/cashout/components/states/accepted';
import { CashoutInputs } from '@features/cashout/components/cashoutInputs';
import { CashoutButton } from '@features/cashout/components/cashoutButton';
import { EventsNames } from '@features/events/components/eventsNames';
import { Selections } from '@features/events/components/selections';
import services from '@features/core/services';
import { PageName } from '@features/core/routing/linkAliases';
import { useCashoutItemModel } from '@features/cashout/useCashoutItemModel';

import { HAS_CATEGORIES_FAVORITES } from '@common/constants/config';
import { elementScrollIntoView, scrollTo } from '@common/helpers/scrollHelper';
import deleteTimeout from '@common/helpers/timeoutHelper';
import { ButtonType, TTimeout } from '@common/interfaces';
import { setLocation } from '@common/providers/router/helper';
import {
  STATE_ACCEPTED,
  STATE_ERROR,
  STATE_QUEUED,
} from '@common/providers/cashout/state';
import { formatTypeString } from '@common/helpers/cashoutHelper';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { setSelectedBetId } from '@common/providers/bets/useBets';
import {
  addCashoutFavorites,
  removeCashoutFavorites,
  removeRequestedCashout,
} from '@common/providers/cashout/useCashout';
import getImageBasePath from '@common/helpers/getImageBasePath';
import { formatBalance } from '@common/helpers/paymentsHelper/walletsHelper';

import { StyledButton } from '@ui/components/buttons';
import { Icon } from '@ui/components/icon';

import * as S from './CashoutItem.styled';
import { ICashoutItem, StatusBet } from './CashoutItem.types';

const MAX_WINNING_VALUE = 100000;

const CashoutItem: React.FC<ICashoutItem> = ({ betId, even }, ref) => {
  const {
    requestedCashout,
    totalStake,
    unavailableSelectionId,
    selections,
    isFavorite,
    type,
    isBonus,
    isShopEMoney,
    remainingRisk,
    isLive,
  } = useCashoutItemModel(betId);

  const location = useLocation();
  const { accent } = useTheme();
  const loctationState: ILoctationState = location.state as ILoctationState;
  const { id: idCashoutParam }: { id: string } = useParams();
  const state = requestedCashout && requestedCashout.state;
  const [partCashout, setPartCashout] = useState<boolean>(false);
  const [stateStatus, setStateStatus] = useState<StatusBet>(StatusBet.DEFAULT);
  const [extended, setExtended] = useState<boolean>(false);
  const { t } = useTranslation();
  const cashoutItemRef = useRef<HTMLDivElement>(null);
  const timeoutScrollRef = useRef<TTimeout>(null);
  const timeoutExtendDetailRef = useRef<TTimeout>(null);
  const isDesktop = isDesktopView();
  const isDetailVisible = (isDesktop && extended) || !isDesktop;
  const opened =
    betId === loctationState?.cashoutId || betId === idCashoutParam;

  const animationProps = {
    from: { maxHeight: 50 },
    maxHeight: extended ? '100%' : 50,
  };

  const scrollToElem = (): void => {
    if (cashoutItemRef.current && isDesktop) {
      const scrollContainer = document.getElementById('right-layout');

      if (scrollContainer) {
        scrollTo(scrollContainer, {
          top: cashoutItemRef.current.offsetTop,
          behavior: 'smooth',
          duration: 200,
        });
      }
    }

    if (cashoutItemRef.current && !isDesktop) {
      elementScrollIntoView(cashoutItemRef.current, {
        behavior: 'smooth',
        block: 'end',
      });
    }
  };

  useEffect(() => {
    if (opened) {
      setExtended(true);
      timeoutScrollRef.current = setTimeout(scrollToElem, 100);
    }

    return () => {
      deleteTimeout(timeoutScrollRef.current, timeoutExtendDetailRef.current);
    };
  }, []);

  useEffect(() => {
    if (state === STATE_ACCEPTED) {
      setExtended(false);
      setStateStatus(StatusBet.DEFAULT);
    } else if (state === STATE_ERROR) {
      setStateStatus(StatusBet.DISABLED);
    }
  }, [requestedCashout]);

  const extendDetail = (): void => {
    timeoutExtendDetailRef.current = setTimeout(() => {
      setExtended(!extended);
      setStateStatus(StatusBet.DEFAULT);
      if (state === STATE_ERROR) {
        removeRequestedCashout(betId);
      }

      timeoutScrollRef.current = setTimeout(scrollToElem, 500);
    }, 200);
  };

  const goToBetDetails = (): void => {
    if (isDesktop) {
      setSelectedBetId(betId);
    } else {
      setLocation(PageName.USER_BET_ITEM, { id: betId });
    }
  };

  const precisionWinningsValue =
    MAX_WINNING_VALUE > Number(remainingRisk) ? 2 : 0;

  const iconSrc = isFavorite
    ? '/images/cashout-pin-filled-icon.svg'
    : '/images/cashout-pin-non-filled-icon.svg';

  const iconAlt = isFavorite ? 'cashout-favorite' : 'cashout-non-favorite';

  return (
    <div ref={ref}>
      <S.Wrapper ref={cashoutItemRef} data-qa="cashout-item">
        <a.div
          style={{ ...animationProps }}
          className={isDesktop ? '' : 'slide-inner'}
        >
          <div className={`short-view${extended ? ' open' : ''}`}>
            {services.config.get(HAS_CATEGORIES_FAVORITES) && (
              <S.ImageWrapper>
                <S.ImageStyled
                  onClick={
                    (): void =>
                      isFavorite
                        ? removeCashoutFavorites(betId)
                        : addCashoutFavorites(betId)
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  src={getImageBasePath(iconSrc)}
                  alt={iconAlt}
                />
              </S.ImageWrapper>
            )}
            <S.Text onClick={extendDetail}>
              <S.CashoutTitleWrapper open={extended}>
                <S.CashoutTitle isBonus={isBonus === '1'}>
                  {isBonus === '1' && (
                    <Icon name="gift" width="13" height="16" />
                  )}
                  {isShopEMoney === '1' && (
                    <Icon name="credit-card" width="16" height="16" />
                  )}
                  <span>
                    <b>{formatBalance(totalStake)}</b>
                    {' / '}
                    <span title={formatTypeString(type)}>
                      {formatTypeString(type)}
                    </span>
                  </span>
                </S.CashoutTitle>
                {!extended && (
                  <span>
                    {`${t('common.labels.possible_winnings')}: ${formatBalance(
                      remainingRisk,
                      {
                        precision: precisionWinningsValue,
                      },
                    )}`}
                  </span>
                )}
              </S.CashoutTitleWrapper>
              {!extended && (
                <S.LiveEventNamesWrapper>
                  {isLive && (
                    <S.CashoutLive>
                      <Icon
                        name="sonderwetten-outlined"
                        width="10"
                        height="10"
                        fill={accent}
                      />
                      <b>{t('cashout.live')}</b>
                    </S.CashoutLive>
                  )}
                  <S.EventsNamesWrapper>
                    <EventsNames selections={selections} />
                  </S.EventsNamesWrapper>
                </S.LiveEventNamesWrapper>
              )}
              {extended && (
                <S.GewinnChanceWrapper>
                  {`${t('common.labels.possible_winnings')}: ${formatBalance(
                    remainingRisk,
                    {
                      precision: precisionWinningsValue,
                    },
                  )}`}
                </S.GewinnChanceWrapper>
              )}
            </S.Text>

            {extended && (
              <StyledButton
                onClick={goToBetDetails}
                dataQa="cashout-btn-toDetails"
                label="cashout.go_to_details"
                className="go-to-details-btn"
                buttonType={ButtonType.LightOutlined}
              />
            )}

            <S.OpenButton
              open={extended}
              disabled={
                stateStatus === StatusBet.DISABLED ||
                state === STATE_ACCEPTED ||
                state === STATE_QUEUED
              }
              className="shevron-wrapper"
              onClick={extendDetail}
              data-qa="cashout-btn-toggleItem"
              aria-label={
                !extended
                  ? t('common.buttons.open_bet_detail')
                  : t('common.buttons.close_bet_detail')
              }
            >
              <Icon name="chevron-down" width="10" height="10" />
            </S.OpenButton>
          </div>

          {isDetailVisible && (
            <div className="extended-view">
              <Selections
                type="cashout"
                even={even}
                betId={betId}
                selections={selections}
                unavailableSelectionId={unavailableSelectionId}
              />

              <BetStats
                betId={betId}
                totalStake={totalStake}
                remainingRisk={remainingRisk}
              />

              {state === STATE_ERROR && (
                <Rejected
                  onClose={(): void => {
                    setStateStatus(StatusBet.DEFAULT);
                    removeRequestedCashout(betId);
                  }}
                  message={requestedCashout?.error?.message}
                />
              )}
            </div>
          )}
        </a.div>

        {!extended && state !== STATE_ACCEPTED && (
          <CashoutButton
            betId={betId}
            stateStatus={stateStatus}
            partCashout={partCashout}
            setStateStatus={setStateStatus}
            setPartCashout={setPartCashout}
          />
        )}

        {extended && (
          <CashoutInputs
            betId={betId}
            closeExtended={extendDetail}
            scrollToElem={scrollToElem}
            setStateStatus={setStateStatus}
            setPartCashout={setPartCashout}
          />
        )}

        {state === STATE_ACCEPTED && (
          <Accepted
            partCashout={partCashout}
            paidStatus={requestedCashout?.paid_status}
            callBack={(): void => {
              removeRequestedCashout(betId);
            }}
          />
        )}

        {state === STATE_ERROR && !extended && (
          <Rejected
            onClose={(): void => {
              setStateStatus(StatusBet.DEFAULT);
              removeRequestedCashout(betId);
            }}
            message={requestedCashout?.error?.message}
          />
        )}
      </S.Wrapper>
    </div>
  );
};

export { CashoutItem as Component };

export default forwardRef(
  CashoutItem as React.ForwardRefRenderFunction<ICashoutItem, ICashoutItem>,
);
