import reduce from 'lodash/reduce';
import assign from 'lodash/assign';

import services from '@features/core/services';

import { ICMSContent } from '@common/providers/application/types';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import { DynamicCalls } from '@common/api/api';

const { API_STATIC_PAGE_CONTENT } = DynamicCalls;

const getValue = (key, partnersData): string => {
  const urlParams = new URLSearchParams(window.location.search);
  return (
    partnersData[key] || services.cookie.get(key) || urlParams.get(key) || ''
  );
};

export const getAffiliateParams = async (
  affiliateId: string,
): Promise<{
  btag?: string;
  ia_affid?: string;
  affiliate_id?: string;
  atag?: string;
}> => {
  const partersResponse = await apiHandler<ICMSContent>(
    { method: MethodType.Get },
    {
      apiUrl: API_STATIC_PAGE_CONTENT,
      apiData: {
        dynamicLanguage: services.domainLang,
        dynamicType: 'partners-register-params',
      },
    },
  );

  let partners = {};
  let partnersData = {};
  try {
    partners = JSON.parse(partersResponse.body as string);
  } catch (e) {
    services.logger?.log(String(e));
  }

  partnersData = partners[affiliateId] || {};

  return reduce(
    ['btag', 'ia_affid', 'affiliate_id', 'atag'],
    (acc, key) => assign(acc, { [key]: getValue(key, partnersData) }),
    {},
  );
};

export default getAffiliateParams;
