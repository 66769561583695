import React from 'react';

import * as S from './ErrorContainer.styled';
import { IErrorContainer } from './ErrorContainer.types';

const ErrorContainer: React.FC<IErrorContainer> = props => {
  const {
    children,
    errors,
    floatError,
    isFloatErrorArrowLeft,
    isRelativeErrorPosition,
  } = props;
  const isArrow = !!document.getElementById('error-arrow');

  return (
    <S.InputContainer
      className={`form-input-error ${errors.length ? 'with-errors' : ''}`}
      data-qa={`form-input-error ${errors.length ? 'with-errors' : ''}`}
      isArrow={isArrow}
      noMargins={isRelativeErrorPosition}
    >
      {children}
      {!!errors.length && (
        <S.Errors
          floatError={floatError}
          isFloatErrorArrowLeft={isFloatErrorArrowLeft}
          isRelativeErrorPosition={isRelativeErrorPosition}
          className="error-message"
          dangerouslySetInnerHTML={{ __html: errors.join(', ') }}
        />
      )}
    </S.InputContainer>
  );
};

export default ErrorContainer;
