import { ICustomError, IUser } from '@common/interfaces';

export interface IUserState {
  data: IUser | null;
  isLoggedIn: boolean;
  error: ICustomError | null;
  loading: boolean;
  cashoutStatus?: string;
  switchAccountLoading: boolean;
  onlineAccountError: string;
  onlineAccountSuccess: boolean;
  onlineAccountLoading: boolean;
  isAuthEnded: boolean;
}

export const userDefaultState: IUserState = {
  error: null,
  data: null,
  isLoggedIn: false,
  loading: false,
  cashoutStatus: '',
  switchAccountLoading: false,
  onlineAccountError: '',
  onlineAccountSuccess: false,
  onlineAccountLoading: false,
  isAuthEnded: false,
};

export type IFetchSwitchAccount = {
  result: {
    secret_token: string;
    session_id: string;
    user_id: string;
  };
};

export type ICreateOnlineAccount = {
  result?: {
    user_id?: string;
    account_id: string;
    description: string;
    error?: number;
    message?: string;
  };
};

export type IUpdateOnlineAccountPayload = {
  email: string;
  newsletter_agree: boolean;
  maidenname?: string;
  country_code?: string;
  country_of_birth?: string;
  place_of_birth?: string;
  nationality?: string;
  address_street_name?: string;
  address_street_number?: string;
  address_zip_code?: string;
  address_city?: string;
  region?: string;
  deposit_monthly_limit?: string;
};

export type ICreateOnlineWalletPayload = {
  email: string;
  newsletter_agree: boolean;
  deposit_monthly_limit?: string;
};
