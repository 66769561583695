import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import find from 'lodash/find';
import join from 'lodash/join';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import keys from 'lodash/keys';

import { CollapsedCategoriesList } from '@features/categories/collapsedCategoriesList';
import { PageName } from '@features/core/routing/linkAliases';
import { NoFavoriteView } from '@features/categories/ui/noFavoriteView';
import services from '@features/core/services';

import useNotification from '@common/hooks/useNotification';
import { HAS_CATEGORIES_FAVORITES } from '@common/constants/config';
import { setLocation } from '@common/providers/router/helper';
import { useFavoritesState } from '@common/providers/favorites/useFavoritesState';
import { getCheckedCategories } from '@common/helpers/categories/categories';
import { ILiveSportCategoriesTreeData } from '@common/interfaces';
import {
  extendRequestCategories,
  filterCategoriesTree,
  getItemFilterQuery,
  processChildren,
} from '@common/helpers/categories/categoriesModel';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { isLongTerm as isLongTermCategory } from '@common/helpers/eventsHelper/eventStatusHelper';
import getEventRoute from '@common/helpers/eventsHelper/eventRouteHelper';
import checkItem from '@common/helpers/eventsHelper/eventCheckHelper';
import {
  getFavoritesData,
  isCategoryFavorite,
} from '@common/helpers/favoritesHelper';
import { scrollTopWindow } from '@common/helpers/scrollHelper';
import { useLiveCategories } from '@common/providers/events/liveCategories/useLiveCategories';

import { CollabsibleContainer } from '@ui/components/collabsibleContainer';

import * as S from './CategoriesList.styled';
import ICategoriesListProps from './CategoriesList.types';

const isDesktop = isDesktopView();

const CategoriesList: React.FC<ICategoriesListProps> = props => {
  const { isHighlights, isLongTerm, isFavorite, hasFavoritesInfoView } = props;
  const { t } = useTranslation();
  const favoritesCategories = useFavoritesState(s => s.favoritesCategories);
  const selectedSportCategory = useLiveCategories(
    s => s.selectedSportCategory[0],
  );
  const categoryWeight = useLiveCategories(
    s => s.highlightsMeta.categoryWeight,
  );
  const timeFilter = useLiveCategories(s => s.sportCategoriesTree.filter);
  const sportCategoriesTree = useLiveCategories(
    s => s.sportCategoriesTree.data,
  );

  // functionality for filtering categories
  const extendedCategories: string[] = extendRequestCategories([
    selectedSportCategory,
  ]);

  const getCategoriesBySport = (
    sportCategories: string[],
  ): ILiveSportCategoriesTreeData[] =>
    reduce(
      sportCategories,
      (acc: ILiveSportCategoriesTreeData[], item: string) => {
        const sport = find(sportCategoriesTree, ['id', item]);
        if (sport && sport.children && !isDesktop) {
          return acc.concat(processChildren(sport.children, 2));
        }
        if (sport && sport.children && isLongTerm) {
          return acc.concat(processChildren(sport.children, 2));
        }
        if (sport && sport.children) {
          return acc.concat(sport.children);
        }
        return acc;
      },
      [],
    );

  const highlightCategories: ILiveSportCategoriesTreeData[] = filterCategoriesTree(
    isDesktop
      ? sportCategoriesTree
      : filter(sportCategoriesTree, ['id', selectedSportCategory]),
    categoryWeight.sports,
  );

  const highlightLongTermCategories: ILiveSportCategoriesTreeData[] = filterCategoriesTree(
    isDesktop
      ? sportCategoriesTree
      : filter(sportCategoriesTree, ['id', selectedSportCategory]),
    categoryWeight.longterm,
  );

  const allCategories: ILiveSportCategoriesTreeData[] = getCategoriesBySport(
    extendedCategories,
  );

  const regularCategories: ILiveSportCategoriesTreeData[] = filter(
    allCategories,
    item => !isLongTermCategory(item),
  );

  const longTermCategories: ILiveSportCategoriesTreeData[] = filter(
    allCategories,
    item => isLongTermCategory(item),
  );

  const getCategoriesToRender = (): ILiveSportCategoriesTreeData[] => {
    if (isHighlights && isLongTerm) {
      return highlightLongTermCategories;
    }
    if (isHighlights) {
      return highlightCategories;
    }
    if (isLongTerm) {
      return longTermCategories;
    }
    return regularCategories;
  };
  // functionality for handlers (check & select categories on desktop)
  const checkedCategories: string[] = getCheckedCategories();

  const selectItem = getEventRoute(timeFilter, sportCategoriesTree);

  // functionality for AllEventsLink handler
  const getAllEventsLinkSearch = (
    currentCategories: ILiveSportCategoriesTreeData[],
  ): string => {
    const currentCategoriesIds: string[] = reduce(
      currentCategories,
      (acc: string[], item: ILiveSportCategoriesTreeData) => {
        acc.push(item.id);
        return acc;
      },
      [] as string[],
    );
    return join(currentCategoriesIds, ',');
  };

  const onAllEventsLink = (isFavoriteSection: boolean): void => {
    const currentCategories = getCategoriesToRender();
    const currentFavoriteCategories = keys(favoritesCategories).join();

    let search;
    if (isFavoriteSection) {
      search = `?categories=${currentFavoriteCategories}`;
    } else if (isLongTerm || isHighlights) {
      search = `?categories=${getAllEventsLinkSearch(currentCategories)}`;
    } else {
      search = '';
    }

    setLocation(
      PageName.EVENTS_CATEGORY,
      {
        categoryId: selectedSportCategory,
        filter: getItemFilterQuery(timeFilter),
      },
      { forceReload: true, search },
    );
  };

  const filteredFavoriteCategories = useMemo(() => {
    return getFavoritesData(sportCategoriesTree, favoritesCategories);
  }, [
    favoritesCategories,
    regularCategories,
    highlightCategories,
    sportCategoriesTree,
  ]);

  const hasFavorite =
    services.config.get(HAS_CATEGORIES_FAVORITES) &&
    !!filteredFavoriteCategories?.length &&
    isFavorite;

  // render methods
  const renderTitle = (isFavoriteSection: boolean): JSX.Element => {
    let title;

    if (isFavoriteSection) {
      title = t('common.labels.favorites');
    } else if (isHighlights) {
      title = t('common.labels.highlights');
    } else {
      title = t('common.labels.categories');
    }
    return (
      <S.Title>
        <span>{t(title)}</span>

        {((getCategoriesToRender().length !== 0 && !isFavoriteSection) ||
          (isFavoriteSection && hasFavorite)) && (
          <S.AllEventsLink
            onClick={(): void => onAllEventsLink(isFavoriteSection)}
          >
            {t('common.labels.all_games')}
          </S.AllEventsLink>
        )}
      </S.Title>
    );
  };

  const renderCategories = (category: string): JSX.Element[] => {
    const sportCategories = getCategoriesToRender();
    const filteredCategories = filter(sportCategories, item => {
      return !isCategoryFavorite(item.id, favoritesCategories);
    });

    const mergedCategories = {
      highlighted: filteredCategories,
      all: sportCategories,
      favorite: filteredFavoriteCategories,
    };

    return map(mergedCategories[category], item => {
      const handleClick = (clickedItem): void => {
        checkItem(
          clickedItem,
          checkedCategories,
          timeFilter,
          sportCategoriesTree,
        );
      };
      return (
        <S.Item
          key={item.id}
          isFavorite={
            !!favoritesCategories[item.id] &&
            !!(hasFavorite || hasFavoritesInfoView)
          }
        >
          <CollapsedCategoriesList
            item={item}
            filterData={timeFilter}
            onClick={handleClick}
            onSelect={selectItem}
            isHighlight={isHighlights || false}
          />
        </S.Item>
      );
    });
  };

  // side effects
  useEffect(() => {
    scrollTopWindow();
  }, [selectedSportCategory, timeFilter]);

  const isHighlightsEmpty =
    getCategoriesToRender().length === 0 && isHighlights;
  const isTopNotification = useNotification();

  return (
    <S.Root data-qa="categories-list" isTopNotification={!!isTopNotification}>
      {!isDesktop && (hasFavorite || hasFavoritesInfoView) && renderTitle(true)}

      {(hasFavorite || hasFavoritesInfoView) &&
        (isDesktop ? (
          <CollabsibleContainer title={t('common.labels.favorites')}>
            {hasFavorite ? (
              renderCategories('favorite')
            ) : (
              <NoFavoriteView
                message={t('home_page.favorites.no_favorites_desktop_text')}
              />
            )}
          </CollabsibleContainer>
        ) : (
          <S.FavoritesMobileWrapper>
            {hasFavorite ? (
              renderCategories('favorite')
            ) : (
              <NoFavoriteView
                message={t('home_page.favorites.no_favorites_mobile_text')}
              />
            )}
          </S.FavoritesMobileWrapper>
        ))}

      {!isDesktop && !isHighlightsEmpty && renderTitle(false)}
      {isDesktop && isHighlights ? (
        <CollabsibleContainer title={t('common.labels.highlights')}>
          {renderCategories('highlighted')}
        </CollabsibleContainer>
      ) : (
        renderCategories(isHighlights ? 'highlighted' : 'all')
      )}
      {getCategoriesToRender().length === 0 && !isHighlights && !isFavorite && (
        <S.EmptyList>
          <S.EmptyListTitle className="title">
            {t('categoriesPage.no_categories_title')}
          </S.EmptyListTitle>
          <S.EmptyListMessage
            message={t('categoriesPage.no_categories_message')}
          />
        </S.EmptyList>
      )}
    </S.Root>
  );
};

export default CategoriesList;
