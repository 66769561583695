/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import head from 'lodash/head';
import keys from 'lodash/keys';

import CustomError, {
  convertResponseToError,
} from '@features/core/error/error';

import {
  EventListTypes,
  ICustomError,
  IEvent,
  IEventData,
  IEventUpdates,
  IEventsState,
  IMarketType,
  IMarketsKeysSets,
  IResponseError,
  ISelectedEvent,
} from '@common/interfaces';
import { defaultState } from '@common/providers/events/eventList/state';
import {
  IGetEventsListPayload,
  IMarketKeyDetails,
  MergedType,
} from '@common/providers/events/eventList/types';
import {
  getApplySince,
  getGlobalQueue,
} from '@common/helpers/eventsHelper/globalSocketData';
import { eventUpdates } from '@common/helpers/updatesHelper';
import fetchEventsList from '@common/api/events/fetchEventsList';
import fetchEndedEventList from '@common/api/events/fetchEndedEventList';
import { parseEventList } from '@common/helpers/eventsHelper/eventDataHelper';
import { isDesktopView } from '@common/helpers/deviceUtil';

export const useEventsListState = createWithEqualityFn<IEventsState>(
  () => defaultState,
  shallow,
);

export const startSettingEventList = (
  listType: keyof typeof EventListTypes,
) => {
  useEventsListState.setState(state => {
    return {
      ...state,
      [listType]: {
        ...state[listType],
        loading: true,
      },
    };
  });
};

export const setEventsList = (
  payload: MergedType<
    IEventData,
    { listType: keyof typeof EventListTypes; loadedEvent?: string | string[] }
  >,
) => {
  const { listType, loadedEvent } = payload;
  const index = getApplySince(payload.curr_version as number);

  useEventsListState.setState(state => {
    return eventUpdates(getGlobalQueue(index), {
      ...state,
      [listType]: {
        ...omit(state[listType], 'data'),
        data: payload,
        loadedEvent:
          listType === EventListTypes.detail ? loadedEvent : undefined,
        loading: false,
      },
    });
  });
};

export const updateEventData = (
  event: Record<string, IEvent>,
  listType: keyof typeof EventListTypes,
) => {
  const eventId = head(keys(event));
  useEventsListState.setState(state => {
    const eventExist = has(state, `${listType}.data.events.${eventId}`);
    if (!eventExist) {
      return state;
    }
    return {
      ...state,
      [listType]: {
        ...state[listType],
        data: {
          ...state[listType].data,
          events: {
            ...state[listType].data.events,
            ...event,
          },
        },
      },
    };
  });
};

export const resetEventsList = (listType: keyof typeof EventListTypes) => {
  useEventsListState.setState(state => {
    return {
      ...state,
      [listType]: {
        ...defaultState[listType],
        loadedEvent: state[listType].loadedEvent,
      },
    };
  });
};

export const removeEventFromList = payload => {
  useEventsListState.setState(state => {
    const { id, list } = payload;
    const currentListState = state[list];

    if (currentListState?.data && currentListState.data.events) {
      const newEvents = { ...currentListState.data.events };
      delete newEvents[id];

      return {
        ...state,
        [list]: {
          ...currentListState,
          data: {
            ...currentListState.data,
            events: newEvents,
          },
          loading: false,
        },
      };
    }

    return state;
  });
};

export const setEventsListError = (
  payload: ICustomError<{ listType: keyof typeof EventListTypes }>,
) => {
  useEventsListState.setState(state => {
    const { data } = payload;
    return data
      ? {
          ...state,
          [data?.listType]: {
            ...state[data?.listType],
            error: payload,
            loading: false,
          },
        }
      : state;
  });
};

export const setMarketFilterKey = (payload: IMarketKeyDetails) => {
  useEventsListState.setState(state => {
    const { listType, marketKey } = payload;
    return {
      ...state,
      [listType]: {
        ...state[listType],
        filterMarketKey: marketKey,
      },
    };
  });
};

export const setEventsSocketData = (payload: IEventUpdates) => {
  useEventsListState.setState(state => {
    const updates = payload;
    return eventUpdates(updates, state);
  });
};

export const setSelectedEvent = (payload: ISelectedEvent | null) => {
  useEventsListState.setState(state => {
    return {
      ...state,
      detail: {
        ...state.detail,
        selectedEvent: payload,
      },
    };
  });
};

export const clearEventSuspendReason = (payload: {
  listType: keyof typeof EventListTypes;
  eventId: string;
}) => {
  useEventsListState.setState(state => {
    const { eventId, listType } = payload;
    return {
      ...state,
      [listType]: {
        ...state[listType],
        data: {
          ...state[listType].data,
          events: {
            ...state[listType].data.events,
            [eventId]: {
              ...state[listType].data.events[eventId],
              suspend_reason: null,
              suspended_timer: null,
            },
          },
        },
      },
    };
  });
};

export const setMarketsKeysSets = (payload: IMarketsKeysSets) => {
  useEventsListState.setState(state => {
    return {
      ...state,
      live: {
        ...state.live,
        marketsKeysSets: { ...state.live.marketsKeysSets, ...payload },
      },
    };
  });
};

export const setMarketsKeysItem = (payload: {
  key: string;
  val: {
    fullTimeMarketKeys: IMarketType[];
    halfTimeMarketKeys: IMarketType[];
    penaltyMarketKeys: IMarketType[];
  };
}) => {
  useEventsListState.setState(state => {
    return {
      ...state,
      live: {
        ...state.live,
        marketsKeysSets: {
          ...state.live.marketsKeysSets,
          [payload.key]: payload.val,
        },
      },
    };
  });
};

// IGetEventsListPayload all fields are optional (not good)
export const getEventsList = async (
  params: IGetEventsListPayload,
): Promise<void> => {
  const { listType, events: eventIds, lang } = params;
  startSettingEventList(listType);
  try {
    const unparsedEventsList = await fetchEventsList(params);

    if (listType === 'detail' && isEmpty(unparsedEventsList.events)) {
      const unparsedEndedEventsList = await fetchEndedEventList(
        eventIds as string,
        lang,
      );
      const parsedEndedEventsList: IEventData = parseEventList(
        unparsedEndedEventsList,
      );
      setEventsList({
        ...parsedEndedEventsList,
        listType,
        loadedEvent: eventIds,
      });
    } else {
      const parsedEventList: IEventData = parseEventList(unparsedEventsList);
      setEventsList({ ...parsedEventList, listType, loadedEvent: eventIds });
      if (listType === EventListTypes.detail && isDesktopView()) {
        updateEventData(parsedEventList.events, EventListTypes.live);
      }
    }
  } catch (e) {
    setEventsListError(
      new CustomError({
        message: convertResponseToError(e as IResponseError),
        data: {
          listType,
        },
      }),
    );
  }
};
