import services from '@features/core/services';
import CustomError from '@features/core/error/error';

import { GCMS_PROXY_URL } from '@common/constants/config';

type apiResponse = {
  id: number;
  userId: string;
} & CustomError;

export const postGCMSForm = async (payload): Promise<apiResponse> => {
  return services.http.put(
    `${services.config.get(GCMS_PROXY_URL)}/gcms-key-value`,
    payload,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 1800000, // 30 minutes
    },
  );
};

export default postGCMSForm;
