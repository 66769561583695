import { PageName } from '@features/core/routing';
import services from '@features/core/services';
import history from '@features/core/router/history';

import { HAS_EVENT_DETAIL_POPUP } from '@common/constants/config';
import {
  EventListTypes,
  ILiveSportCategoriesTreeData,
} from '@common/interfaces';
import { setLocation } from '@common/providers/router/helper';
import { IParams } from '@common/providers/router/types';
import {
  setSelectedEvent,
  useEventsListState,
} from '@common/providers/events/eventList/useEventsList';

import {
  getAllChildrenIds,
  getIdsPathToCategory,
  getItemFilterQuery,
} from '../categories/categoriesModel';
import ScrollTopElement from '../scrollTopElement';
import { getTestList, isDesktopView } from '../deviceUtil';

const isDesktop = isDesktopView();

const shouldOpenCategory = (payload: {
  eventId: string;
  categoryId?: string;
  topCategoryId?: string;
}): boolean => {
  const { events } = useEventsListState.getState()[
    EventListTypes.combined_events
  ].data;
  const liveEvents = useEventsListState.getState()[EventListTypes.live].data
    .events;

  if (
    payload?.topCategoryId &&
    payload?.categoryId &&
    !events[payload.eventId] &&
    !liveEvents[payload.eventId]
  ) {
    const search = `?categories=${payload.categoryId}&events=${payload.eventId}`;

    setLocation(
      PageName.EVENTS_CATEGORY,
      {
        categoryId: payload.topCategoryId,
        filter: 'all',
      },
      { forceReload: true, search },
    );
    return true;
  }
  return false;
};

export const closeEventDetail = (isHistoryReplace = false): void => {
  setSelectedEvent(null);
  const urlParams = new URLSearchParams(window.location.search);
  const historyAction = isHistoryReplace ? 'replace' : 'push';
  urlParams.delete('events');
  history[historyAction]({
    search: String(urlParams),
  });
};

export const openEventDetail = ({
  eventId,
  categoryId,
  topCategoryId,
  isStatisticsTabOpen = false,
  params,
}: {
  eventId: string;
  categoryId?: string;
  topCategoryId?: string;
  isStatisticsTabOpen?: boolean;
  params?: IParams;
}): void => {
  const eventsState = useEventsListState.getState();
  const selectedEventId = eventsState.detail.selectedEvent?.eventId;
  if (services.config.get(HAS_EVENT_DETAIL_POPUP) || isDesktop) {
    if (selectedEventId && selectedEventId === eventId) {
      return closeEventDetail();
    }
    const wasLocationSet =
      isDesktop &&
      shouldOpenCategory({
        eventId,
        categoryId,
        topCategoryId,
      });
    const { search } = window.location;
    const urlParams = new URLSearchParams(search);
    urlParams.set('events', eventId);
    const testList = getTestList();
    // eslint-disable-next-line no-unused-expressions
    !wasLocationSet &&
      history.push({
        search: testList
          ? `testlist=${testList}&${decodeURIComponent(urlParams.toString())}`
          : decodeURIComponent(urlParams.toString()),
      });

    return setSelectedEvent({
      eventId,
      isStatisticsTabOpen,
    });
  }
  return setLocation(PageName.EVENTS_ITEM, { eventId }, params);
};

const getEventRoute = (timeFilter, sportCategoriesTree) => async (
  item: ILiveSportCategoriesTreeData,
): Promise<void> => {
  if (isDesktop) {
    ScrollTopElement('main-container');

    const childrenCategories: string[] = getAllChildrenIds(item);
    const rootItem = getIdsPathToCategory(sportCategoriesTree, item.id)[0];

    if (item.children?.length) {
      setLocation(
        PageName.EVENTS_CATEGORY,
        {
          categoryId: rootItem,
          filter: getItemFilterQuery(timeFilter),
        },
        {
          forceReload: true,
          search: `?categories=${childrenCategories.join(',')}`,
        },
      );
    } else {
      setLocation(
        PageName.EVENTS_CATEGORY,
        {
          categoryId: rootItem,
          filter: getItemFilterQuery(timeFilter),
        },
        { forceReload: true, search: `?categories=${item.id}` },
      );
    }
  }

  if (!isDesktop) {
    setLocation(PageName.EVENTS_CATEGORY, {
      categoryId: item.id,
      filter: getItemFilterQuery(timeFilter),
    });
  }
};

export default getEventRoute;
