import React, { useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import services, { useServices } from '@features/core/services';
import { PageName } from '@features/core/routing/linkAliases';

import { isMatchWebRoutes, setLocation } from '@common/providers/router/helper';
import { STATE_OPENED } from '@common/providers/bettingslip/state';
import { hasSelections } from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import {
  changeBettingSlipState,
  resetBetPackerSelection,
  resetBSpredictions,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';
import useOnClickOutside from '@common/hooks/useOnClickOutside';
import { HAS_MY_BETS } from '@common/constants/config';
import useCountDown from '@common/hooks/useCountDown';

import { ButtonType } from '@ui/components/buttons/Button.styled';
import { StyledButton } from '@ui/components/buttons';

import * as S from './Accepted.styled';
import IAcceptedProps from './Accepted.types';

const AcceptedUnwrapped: React.FC<IAcceptedProps> = props => {
  const { id } = props;
  const { startCountDown } = useCountDown();
  const theme: { main: string } = useTheme();
  const { t } = useTranslation();
  const selections = useBettingSlip(state => state.selections);
  const betPackerSelections = useBettingSlip(s => s.betPackerSelections);
  const restoredSelections = useBettingSlip(s => s.restoredSelections);
  const state = useBettingSlip(s => s.state);

  const { isDesktopView } = useServices();
  const containerRef = useRef<HTMLDivElement>(null);

  const isBetDetail = isMatchWebRoutes(PageName.USER_BET_ITEM);

  const resetAllSelections = (): void => {
    changeBettingSlipState(STATE_OPENED);
    resetBSpredictions();
    resetBetPackerSelection();
  };

  useEffect(() => {
    startCountDown(resetAllSelections, 0);
    return () => {
      if (
        state === STATE_OPENED &&
        hasSelections(selections, betPackerSelections, restoredSelections)
      ) {
        resetAllSelections();
      }
    };
  }, []);

  const handleRedirect = (): void => {
    resetBSpredictions();
    if (isDesktopView) {
      setLocation(PageName.USER_BETS, {}, { search: `?id=${id}` });
    } else {
      setLocation(PageName.USER_BET_ITEM, { id });
      if (isBetDetail) {
        window.location.reload();
      }
    }
    resetAllSelections();
  };

  const onReuseBetHandler = (): void => {
    changeBettingSlipState(STATE_OPENED);
    if (!isDesktopView) {
      setLocation(PageName.BETTING_SLIP);
    }
  };

  useOnClickOutside(containerRef, () => {
    resetAllSelections();
  });

  return (
    <S.Selection ref={containerRef}>
      <S.SelectionText className="delay">
        <S.SelectionSuccess>
          <S.SuccessIcon
            name="check-circle"
            width="27px"
            height="27px"
            fill={theme.main}
          />
          {t('bettingslip.wait')}
        </S.SelectionSuccess>
      </S.SelectionText>
      <S.ButtonWrapper>
        <StyledButton
          onClick={onReuseBetHandler}
          label="bettingslip.reuse_bettingslip"
          dataQa="reuse-bettingSlip"
          buttonType={ButtonType.Blue}
        />
        {services.config.get(HAS_MY_BETS) && (
          <StyledButton
            onClick={handleRedirect}
            label="bettingslip.showBettingSlip"
            dataQa="show-bettingSlip"
            buttonType={ButtonType.DarkOutlined}
          />
        )}
      </S.ButtonWrapper>
      <S.CloseIcon
        name="close"
        className="closeBtn"
        width="14px"
        height="14px"
        fill={theme.main}
        onClick={resetAllSelections}
      />
    </S.Selection>
  );
};

export { AcceptedUnwrapped as Component };

export default AcceptedUnwrapped;
