import find from 'lodash/find';
import includes from 'lodash/includes';
import { useMemo } from 'react';

import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import {
  cashoutDefaultButtonValue,
  isLiveOrder,
} from '@common/helpers/cashoutHelper';
import { useBets } from '@common/providers/bets/useBets';
import { useCashoutState } from '@common/providers/cashout/useCashout';
import { IBetDetail } from '@common/interfaces/bets/IBetDetail';
import { ICashoutModel } from '@common/interfaces/cashout/ICashout';

const useCashoutItemModel = (betId: string): ICashoutModel => {
  const openBets = useBets(s => s.openBets);
  const updates = useBets(s => s.updates);
  const bet_detail = useBets(s => s.bet_detail);
  const events = useEventsListState(state => state.cashout.data.events);
  const cashoutFavorites = useCashoutState(s => s.favorites);
  const cashoutRequested = useCashoutState(s => s.requested);
  const minCashout = useCashoutState(s => s.min_cashout);

  return useMemo(() => {
    const cashoutItem = find(openBets, ['id', betId]) as IBetDetail;
    const updatedItem = { ...cashoutItem, ...bet_detail, ...updates[betId] };
    const requestedCashout = cashoutRequested[betId];
    // do not use here value from bet api = updatedItem?.cashout_offer
    // we need fresh cashout value from sockets = updates?.[betId]?.cashout_offer
    const cashoutOffer =
      requestedCashout?.cashout_detail?.amount ||
      updates?.[betId]?.cashout_offer ||
      cashoutDefaultButtonValue;
    const cashoutDisabled = parseFloat(cashoutOffer) < minCashout;
    const isFavorite = includes(cashoutFavorites, betId);
    const isLive = isLiveOrder(events, updatedItem);

    return {
      isFavorite,
      cashoutOffer,
      requestedCashout,
      isLive,
      cashoutDisabled,
      offerToken: updatedItem.offer_token,
      remainingRisk: updatedItem.remaining_risk,
      selections: updatedItem.selections,
      totalStake: updatedItem.total_stake,
      type: updatedItem.type,
      unavailableSelectionId: updatedItem.unavailable_selection_id,
      isBonus: updatedItem.is_bonus,
      isShopEMoney: updatedItem.is_shop_emoney,
    };
  }, [betId, updates, cashoutFavorites, cashoutRequested]);
};

export default useCashoutItemModel;
