import { PageName } from '@features/core/routing';
import services from '@features/core/services';

import {
  HAS_BONUS_CODES,
  HAS_BONUS_WALLETS,
  HAS_DEPOSITS,
  HAS_GDPR,
  HAS_INBOX,
  HAS_LIMITS,
  HAS_MANUAL_BARCODE,
  HAS_ODDS_COMPARISON,
  HAS_TICKET_SCANNER,
  HAS_UPLOADS,
  HAS_WITHDRAWALS,
  HAS_YEARLY_DATA_CHECK,
  HAS_MY_FAVORITO,
  HAS_LITTLE_CASHOUT,
} from '@common/constants/config';

export const commonRoutes: string[] = [
  PageName.HOME,
  PageName.EVENTS_CATEGORY,
  PageName.LIVE,
  PageName.CASINO,
  PageName.EVENTS,
  PageName.REGISTER,
  PageName.FULL_REGISTER,
  PageName.UPDATE_ONLINE_ACCOUNT,
  PageName.CREATE_ONLINE_WALLET,
  PageName.SCANNER_RESULT,
  PageName.CASHOUT_SCANNER_RESULT,
  PageName.REGISTRATION_BONUS,
  PageName.USER_DELETE_ACCOUNT,
  PageName.SPORT,
  PageName.USER_CHANGE_PASSWORD,
  PageName.FORGOT_PASSWORD,
  PageName.NEWS_CATEGORY,
  PageName.NEWS_ITEM,
  PageName.EVENTS_ITEM_LMT,
  PageName.PRESSE,
  PageName.UNTERNEHMEN_PRESSE_DETAIL,
  PageName.LOGIN,
  PageName.MIGRATION,
  PageName.TEST_PAGE,
  PageName.BETTING_SLIP,
  ...(services.config.get(HAS_ODDS_COMPARISON)
    ? [PageName.ODDS_COMPARISON]
    : []),
  ...(services.config.get(HAS_TICKET_SCANNER) ? [PageName.TICKET_SCANNER] : []),
  ...(services.config.get(HAS_MANUAL_BARCODE)
    ? [PageName.MANUAL_BARCODE_ENTRY]
    : []),
  ...(services.config.get(HAS_LITTLE_CASHOUT) ? [PageName.LITTLE_CASHOUT] : []),

  PageName.STATIC,
  PageName.SUPPORT,
  PageName.NOT_FOUND,
];

export const iframeUserAccountRoutes: string[] = [
  PageName.USER_BETS,
  PageName.USER_BET_ITEM,
];

export const userAccountRoutes: string[] = [
  PageName.USER_CHANGE_PASSWORD,
  ...(services.config.get(HAS_LIMITS)
    ? [PageName.USER_LIMITS, PageName.USER_LIMITS_LOCK]
    : []),
  PageName.USER_BETS,
  PageName.USER_BET_ITEM,
  PageName.USER_OVERVIEW,
  PageName.USER_PROFILE,
  PageName.USER_NOTIFICATIONS,
  ...(services.config.get(HAS_BONUS_CODES) ||
  services.config.get(HAS_BONUS_WALLETS)
    ? [PageName.USER_BONUSES]
    : []),

  ...(services.config.get(HAS_YEARLY_DATA_CHECK)
    ? [PageName.YEARLY_DATA_CHECK]
    : []),
  ...(services.config.get(HAS_UPLOADS) ? [PageName.USER_UPLOADS] : []),
  ...(services.config.get(HAS_GDPR) ? [PageName.USER_GDPR] : []),
  ...(services.config.get(HAS_MY_FAVORITO) ? [PageName.MY_FAVORITO] : []),
  ...(services.config.get(HAS_INBOX)
    ? [PageName.USER_INBOX, PageName.USER_INBOX_MESSAGE]
    : []),
  ...(services.config.get(HAS_DEPOSITS)
    ? [
        PageName.USER_PAYIN_SUCCESS,
        PageName.USER_PAYIN_FAILURE,
        PageName.USER_PAYIN_PREPARED,
        PageName.USER_PAYIN_INFO_METHOD,
        PageName.USER_PAYIN_CARD_AMOUNT,
        PageName.USER_PAYIN_QR,
      ]
    : []),
  ...(services.config.get(HAS_WITHDRAWALS)
    ? [
        PageName.USER_PAYOUT_SUCCESS,
        PageName.USER_PAYOUT_FAILURE,
        PageName.USER_PAYOUT_PREPARED,
        PageName.USER_PAYOUT_BANK_AMOUNT,
      ]
    : []),
  ...(services.config.get(HAS_WITHDRAWALS) && services.config.get(HAS_DEPOSITS)
    ? [PageName.USER_PAYMENTS]
    : []),
];

export const loggedInRedirectToHomeRoutes: string[] = [
  PageName.REGISTER,
  PageName.LOGIN,
  PageName.FORGOT_PASSWORD,
];

export const loggedOutRedirectToHomeRoutes: string[] = [
  PageName.USER_VERIFICATION,
];
