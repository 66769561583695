import includes from 'lodash/includes';
import pick from 'lodash/pick';

import services from '@features/core/services';
import { PageName } from '@features/core/routing';

import { UserEvents } from '@packages/events/appEvents';

import {
  DEPOSIT_LIMIT_LAYER_COOKIE,
  HAS_ACCEPTED_GLOBAL_LIMITS,
  LUGAS_TIMER,
  TOKEN,
} from '@common/constants/cookie';
import {
  HAS_ACCEPTED_GLOBAL,
  HAS_CHECK_MISS_USER_DATA,
  HAS_PUSH_NOTIFICATION,
  HAS_YEARLY_DATA_CHECK,
} from '@common/constants/config';
import fetchPocketTags from '@common/api/account/fetchPocketTags';
import fetchPaymentAccount from '@common/api/payments/fetchPaymentAccount';
import hasLugasRestriction from '@common/helpers/userHelper/hasLugasRestrictions';
import { setNotifications } from '@common/helpers/appState/notifications';
import { setDialogs } from '@common/helpers/appState/dialogs';
import { MethodType } from '@common/api/apiHandler';
import { authUserSuccess } from '@common/providers/user/useUserState';
import { IUser, IUserType } from '@common/interfaces/user/IUser';
import { setPaymentAccountSuccess } from '@common/providers/payments/usePayments';
import { getMessageCount } from '@common/providers/inbox/helper';
import {
  getPushNotificationStatus,
  getPushSubscriptions,
} from '@common/providers/pushNotifications/helper';
import { IFetchPocketTagsResponse } from '@common/interfaces/api/account/IFetchPocketTags';
import { createExpiresStamp } from '@common/helpers/deviceUtil';
import { ILimit } from '@common/interfaces';
import { setLocation } from '@common/providers/router/helper';
import {
  hasFrozenAcccount,
  isYearlyFrozen,
} from '@common/helpers/paymentsHelper/paymentModel';
import { mustUpdateAccountData } from '@common/helpers/userHelper//userHelper';
import authWallets from '@common/providers/user/actions/authWallets';
import authTags from '@common/providers/user/actions/authTags';
import getAffiliateParams from '@common/providers/user/actions/getAffiliateParams';

export const allowedAffiliates = [
  26487,
  23085,
  43843,
  21764,
  68464,
  84354,
  23776,
  56240,
  60100,
  21554,
  58023,
  38044,
  98996,
];

const checkRedirects = (user: IUser): void => {
  if (
    services.config.get(HAS_YEARLY_DATA_CHECK) &&
    user.personal_information_status === 'show_personal_information'
  ) {
    setLocation(PageName.YEARLY_DATA_CHECK);
  } else if (
    services.config.get(HAS_CHECK_MISS_USER_DATA) &&
    mustUpdateAccountData(user)
  ) {
    setLocation(PageName.UPDATE_ONLINE_ACCOUNT);
  } else if (hasFrozenAcccount(user)) {
    setLocation(
      isYearlyFrozen(user)
        ? PageName.USER_VERIFICATION_YEARLY
        : PageName.USER_VERIFICATION,
    );
  }
};

const checkToSetCookies = (params: {
  lugasActivityTimer?: IFetchPocketTagsResponse;
  depositLayer: IFetchPocketTagsResponse;
  user: IUser;
  monthlyLimit?: ILimit;
}): void => {
  const { lugasActivityTimer, depositLayer, user, monthlyLimit } = params;
  if (
    !services.cookie.get(DEPOSIT_LIMIT_LAYER_COOKIE) &&
    user.type === IUserType.shop &&
    includes(
      allowedAffiliates,
      parseFloat(
        user.affiliateID ||
          ('' &&
            !depositLayer.tag?.value &&
            !parseFloat(monthlyLimit?.data.value as string)),
      ),
    )
  ) {
    services.cookie.set(
      DEPOSIT_LIMIT_LAYER_COOKIE,
      String(depositLayer?.tag?.value),
      {
        expires: createExpiresStamp(60 * 60 * 24 * 365),
      },
    );
  }
  if (
    hasLugasRestriction() &&
    !services.cookie.get(LUGAS_TIMER) &&
    lugasActivityTimer?.tag?.value
  ) {
    services.cookie.set(LUGAS_TIMER, lugasActivityTimer.tag.value, {
      expires: null,
    });
  }
  if (
    services.config.get(HAS_ACCEPTED_GLOBAL) &&
    !services.cookie.get(HAS_ACCEPTED_GLOBAL_LIMITS)
  ) {
    services.cookie.set(
      HAS_ACCEPTED_GLOBAL_LIMITS,
      HAS_ACCEPTED_GLOBAL_LIMITS,
      {
        expires: createExpiresStamp(60 * 60 * 24 * 365),
      },
    );
  }
};

const authOnlineUser = async (user: IUser): Promise<void> => {
  const paymentAccount = await fetchPaymentAccount();
  const wallets = await authWallets(user);

  const affiliateData = await getAffiliateParams(user.affiliateID);

  if (services.cookie.get(TOKEN)) {
    setPaymentAccountSuccess(paymentAccount.account);

    const selfLocking = await fetchPocketTags({
      method: MethodType.Get,
      params: {
        tag: { key: 'sys.self_exclusion' },
      },
    });

    getMessageCount();
    authUserSuccess({
      ...user,
      ...pick(affiliateData, ['btag', 'ia_affid']),
      selfExclusionTag: selfLocking?.tag?.value || '',
    });

    const {
      depositLayer,
      monthlyLimit,
      acceptedLimits,
      hasBoostedLimit,
      lugasActivityTimer,
    } = await authTags(paymentAccount);

    setNotifications(user);
    setDialogs({
      user,
      wallets,
      monthlyLimit,
      depositLayer,
      acceptedLimits,
      hasBoostedLimit,
    });
    checkRedirects(user);
    checkToSetCookies({ lugasActivityTimer, depositLayer, user, monthlyLimit });

    services.events.emitEvent(UserEvents.AUTH, user);
  }
  if (services.config.get(HAS_PUSH_NOTIFICATION)) {
    await getPushNotificationStatus({ username: user.username });

    await getPushSubscriptions({ username: user.username });
  }
};

export default authOnlineUser;
