import forEach from 'lodash/forEach';

import CustomError from '@features/core/error/error';
import services from '@features/core/services';
import {
  comparePageWithCurrentLocation,
  PageName,
} from '@features/core/routing/linkAliases';

import { TOKEN } from '@common/constants/cookie';
import { USE_NEW_SCAN_BET_API } from '@common/constants/config';
import createConnection, {
  connect,
} from '@common/helpers/cashoutHelper/betsUpdater';
import { getEventsFromCashouts } from '@common/helpers/cashoutHelper';
import fetchBets from '@common/api/bets/fetchBets';
import fetchBetDetails from '@common/api/bets/fetchBetDetails';
import { EventListTypes } from '@common/interfaces';
import normalizeBetDetailResponse from '@common/helpers/betDetailHelper';
import { getEventsList } from '@common/providers/events/eventList/useEventsList';
import fetchCashoutBetDetail from '@common/api/bets/fetchCashoutBetDetail';

import {
  getBetsFilter,
  setBetDetail,
  setBetDetailError,
  setBets,
  setBetsDetailLoading,
  setBetsError,
  setBetsLoading,
  setMyBetsLoading,
  useBets,
} from './useBets';
import {
  IBetsSocket,
  IGetBetDetailsPayload,
  IGetBetsPayload,
  ISetBetDetailPayload,
  ISetCashoutBetDetailPayload,
} from './types';

export const getBetDetails = async (
  payload: IGetBetDetailsPayload,
): Promise<void | ISetBetDetailPayload | ISetCashoutBetDetailPayload> => {
  setBetsDetailLoading();
  const betType = payload.barcode ? 'scan-bet' : 'user-bet';
  const useAnonymousScanApi =
    (services.config.get(USE_NEW_SCAN_BET_API) &&
      comparePageWithCurrentLocation(PageName.CASHOUT_SCANNER_RESULT)) ||
    comparePageWithCurrentLocation(PageName.MANUAL_BARCODE_ENTRY);

  const response = useAnonymousScanApi
    ? await fetchCashoutBetDetail(payload)
    : await fetchBetDetails(payload);
  if (response instanceof CustomError || !response) {
    return setBetDetailError(response);
  }

  const betDetail = normalizeBetDetailResponse(betType, response);

  const events = getEventsFromCashouts(betDetail);

  if (events.length) {
    getEventsList({ events, listType: EventListTypes.cashout });
  }

  if (!useAnonymousScanApi && !services.cookie.get(TOKEN)) {
    getEventsList({ events: [], listType: EventListTypes.cashout });
  }

  setBetDetail(
    (response as ISetCashoutBetDetailPayload)?.result?.bet_detail ||
      (response as ISetBetDetailPayload)?.bet_detail,
  );
  return response;
};

export const getBets = async (payload: IGetBetsPayload): Promise<void> => {
  if (payload.list === 'bets' && payload.offset === 0) {
    setMyBetsLoading(true);
  }
  setBetsLoading();
  const response = await fetchBets(payload);
  if (response instanceof CustomError) {
    return setBetsError(response);
  }
  if (payload.isInitial && response.user_bets.length === 0) {
    return getBets({
      items: 20,
      maxAgeDays: 365,
      settled: 'all',
      offset: 0,
      list: 'bets',
    });
  }
  if (payload.list === 'bets') {
    getBetsFilter({
      type: 'all',
      period: payload.maxAgeDays,
      offset: 0,
    });
    setMyBetsLoading(false);
  }
  if (payload.withEvents) {
    const events = getEventsFromCashouts(response.user_bets);
    getEventsList({ events, listType: EventListTypes.cashout });
    if (events.length) {
      return setBets({
        ...response,
        list: payload.list,
      });
    }
  }
  return setBets({
    ...response,
    list: payload.list,
  });
};

export const resetBetsSocket = (
  socket: IBetsSocket | null,
  forceDisconnect = false,
): void => {
  if ((socket && socket.isConnected()) || forceDisconnect) {
    forEach(socket?.channels, channel => channel.leave());
    socket?.disconnect();
  }
};

export const betsSocketReconnect = (): void => {
  const { socket, failedSocketReconnect } = useBets.getState();
  if ((socket && !socket?.isConnected()) || failedSocketReconnect) {
    resetBetsSocket(socket);
    setTimeout(() => createConnection(connect, true), 100);
  }
};
