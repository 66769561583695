export interface IPayment {
  link?: {
    proceed?: {
      href: string;
    };
  };
  deposit?: {
    amount?: string;
    status: string;
    id?: string;
    pocket_id?: string;
    wallet_id?: string;
    fields?: { value: string; name: string }[];
  };
  withdrawal?: {
    status: string;
  };
}

export enum IPaymentOperation {
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
}

export enum IPaymentBalanceOperation {
  CASHBACKS = 'cashbacks',
  BETTINGSLIP = 'bettingslip',
}
